const MarionData = [
	{
		name: 'Boxer Throw Station',
		documents: [
			{
				type: 'Ledger',
				description: 'Original Ledger',
				link: 'https://docs.google.com/spreadsheets/d/18mJoufDxJx2TfN7mGh15hn5hVkLatOBeshfQGlSxc_8/edit#gid=1372949190',
			},

			{
				type: 'Ledger',
				description: 'Updated Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1UdwVTqn24bJnHsgb1gG4YY7nhdUqodWm5kfRFpCTapU/edit#gid=986188978',
			},
		],
	},

	{
		name: 'Bulkhead Assembly Machine',
		documents: [
			{
				type: 'Ledger',
				description: 'Bulkhead Assembly Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1If-UDRPIAAXjtpdFWDKY5XheK8tagcApy6n5pHswbVE/edit?gid=589277354#gid=589277354',
			},

			{
				type: 'SMP',
				description: 'SMP-1000 - TOX POWERPACKAGE CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/document/d/1t11d21wouhndSBMswljAnbFO2CWImeXAynCsZTWSnmI',
			},

			{
				type: 'SMP',
				description: 'SMP-1001 - PARKER 20PRLU PNEUMATIC ROD LOCK REPLACEMENT',
				link: 'https://docs.google.com/document/d/1urdWhAA7RnwE28uIFrqy90pw6phuosX7Xxa6mkiNvik',
			},

			{
				type: 'SMP',
				description: 'SMP-1002 - BMF 19591-044 HOOK JAW AND 19591-045 HEEL JAW REPLACEMENT',
				link: 'https://docs.google.com/document/d/1CvCEeOCxTQRAXVsgvJEkLcI5F2FfrDDKRQzrld4aMp0',
			},

			{
				type: 'SMP',
				description: 'SMP-1003 - NUMATICS P4AK PNEUMATIC CLAMP CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/document/d/11GKGkctlV6N9J9F700p1aZ59gyQLtPxf2RqsD3Gx90c',
			},

			{
				type: 'SMP',
				description: 'SMP-1004 - FABCO-AIR MPS-837 POWER CYLINDER UNIT REPLACEMENT',
				link: 'https://docs.google.com/document/d/1qHKtQLjJuHgIyC7h9zHNNyKSTij3UMF0qjBS357UXDo',
			},

			{
				type: 'SMP',
				description: 'SMP-1005 - MOELLER IHK 75 HD BALL LOCK PUNCH REPLACEMENT',
				link: 'https://docs.google.com/document/d/1Q6DrW3d2-WlUeTroPoNw_ZEbfpRaNyPqwnR7DiMx3Q0',
			},

			{
				type: 'SMP',
				description: 'SMP-1006 - NUMATICS COALESCING FILTER REPLACEMENT',
				link: 'https://docs.google.com/document/d/1pjekPROpqgXahF98XMpMk4hBefZvN37eXu93Wc-CWp4',
			},

			{
				type: 'SMP',
				description: 'SMP-1007 - NUMATICS SOLENOID VALVE REPLACEMENT',
				link: 'https://docs.google.com/document/d/1FzkKV7Gqz9stQ5o9a-A-pTTYHiisewGralaq9beWWD8',
			},
		],
	},

	{
		name: 'Cab Mat (2018)',
		documents: [
			{
				type: 'Ledger',
				description: 'Cab Mat (EM200F) Ledger (Updated)',
				link: 'https://docs.google.com/spreadsheets/d/13HhgZF05mZ6UgsECyWUVJg6-ytMUJG44ahpFSfBiAw4/edit?gid=1204009047#gid=1204009047',
			},
		],
	},

	{
		name: 'Cab Mat (2019)',
		documents: [
			{
				type: 'Ledger',
				description: 'Cab Mat (EM200F) Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1zZsmZxHbhczh8XzRBgubaWOAp2DgDkJ4ExuX49L36ZM/edit?gid=657824894#gid=657824894',
			},

			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Uncoiler Turret Main Bearing Replacement',
				link: 'https://docs.google.com/document/d/1mLqS2V0kV_Xt4pnzF16aG-5foKUsJwe-FTnxk7bFniI',
			},
			{
				type: 'SMP',
				description: 'SMP-004-030 - EM200F Coil Car - Hydraulic Cylinder Inspection',
				link: 'https://docs.google.com/document/d/1RjR0GapRuua3RDbNsH2gkADggr16_Or3WBu7gnNQ4mI',
			},
			{
				type: 'SMP',
				description: 'SMP-004-031 - EM200F Coil Car - Hydraulic Cylinder Replacement',
				link: 'https://docs.google.com/document/d/1-bYY0B8Omujy8VTatLfWGI_QoG9Nk9qY3WEcPEUq4SQ',
			},
			{
				type: 'SMP',
				description: 'SMP-004-032 - EM200F Coil Car - Pinion Gear Replacement',
				link: 'https://docs.google.com/document/d/16rkK--XfZ_pbP8qjeY5r5srKgAAAxEGkr-xdIlGjLfc',
			},
			{
				type: 'SMP',
				description: 'SMP-004-033 - EM200F Coil Car - Leveling Racks Bearing Replacement',
				link: 'https://docs.google.com/document/d/1tNharvoEa-j_U3pURLAd_F-V77JoT6Fpcyia0xpbsxw',
			},
			{
				type: 'SMP',
				description: 'SMP-004-034 - EM200F Coil Car - Hydraulic Motor Replacement',
				link: 'https://docs.google.com/document/d/1IY0zxfaNW26-U18zwLbz2w5CHwK07jiQT22IwBKtANU/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-035 - EM200F Coil Car - Drive Sprocket Replacement',
				link: 'https://docs.google.com/document/d/1pY3oYR1MyjTx_0vOzunR42N65120Kny-Af5pDXLeMdg',
			},
			{
				type: 'SMP',
				description: 'SMP-004-036 - EM200F Coil Car - Drive Chain Replacement',
				link: 'https://docs.google.com/document/d/1B2wW3mLZJJv1TkRRLpPDpY2pXwPnqVV6cSi9B5ziJQU',
			},
			{
				type: 'SMP',
				description: 'SMP-004-037 - EM200F Coil Car - Drive Chain Lubrication',
				link: 'https://docs.google.com/document/d/1cJwvPD0ybJ2K9_v5ZvKO16SMsdJguroZIeu5XjYIbIo',
			},
			{
				type: 'SMP',
				description: 'SMP-004-038 - EM200F Coil Car - Wheel Bearing Lubrication',
				link: 'https://docs.google.com/document/d/1GARV6ksa8OOHIMZUYb1RUbRAgyladTtpK8cCJ10syEU',
			},
			{
				type: 'SMP',
				description: 'SMP-004-039 - EM200F Coil Car - Driven Sprocket(s) Replacement',
				link: 'https://docs.google.com/document/d/1SSA20XQDzDat04hOYz1JQU_xY7oP45aXy9-0LHZs1TA',
			},
			{
				type: 'SMP',
				description: 'SMP-004-040 - EM200F Coil Car - Idler Chain Replacement',
				link: 'https://docs.google.com/document/d/1Gyi6fYacjuzzEaO57jowCGhdrjF4E8d_27ih5Mmv0s4',
			},
			{
				type: 'SMP',
				description: 'SMP-004-041 - EM200F Coil Car - Drive/Idler Shaft Bearing Replacement',
				link: 'https://docs.google.com/document/d/1XZi3cnCzBV6XB_IxMYkEP3WAM0wdQVF_cphf2tDJNKQ',
			},
			{
				type: 'SMP',
				description: 'SMP-004-042 - EM200F Coil Car - Idler Chain Lubrication',
				link: 'https://docs.google.com/document/d/1mpH_F4slPBjYJoZDKv-5X0cHpGEwjbyiB9JZAn317JA',
			},
			{
				type: 'SMP',
				description: 'SMP-004-043 - EM200F Coil Car - Leveling Racks Lubrication',
				link: 'https://docs.google.com/document/d/1RK5PLmhHiQc_vmYYVjj4nWypcv5EMGYfdu_aOaRRj6s',
			},
			{
				type: 'SMP',
				description: 'SMP-004-044 - EM200F Coil Car - Leveling Racks Replacement',
				link: 'https://docs.google.com/document/d/17vi4phJUI43usizNSZkB0csB-MmPe05ML99QeNVnjgo',
			},
			{
				type: 'SMP',
				description: 'SMP-004-045 - EM200F Coil Car - Leveling Rack Bushing Replacement',
				link: 'https://docs.google.com/document/d/1d-EgY8XQwW2CYMcuIhyrcJMAPFwlwaPctMhSFJ9iPqc',
			},
			{
				type: 'SMP',
				description: 'SMP-004-046 - EM200F Coil Car - Drive/Idler Shaft Replacement',
				link: 'https://docs.google.com/document/d/16_j14x6zCH_uVzOAo4vKcp8W74ubbUxAu4u-EQ7QxHQ',
			},
			{
				type: 'SMP',
				description: 'SMP-004-047 - EM200F Turret Indexing Drive - Hydraulic Motor Replacement',
				link: 'https://docs.google.com/document/d/18PIOpy4WqAhyauXKagQpNP2DpqZHJWNe5wBisoMlqOA',
			},
			{
				type: 'SMP',
				description: 'SMP-004-048 - EM200F Turret Indexing Drive - Gear Reducer Replacement',
				link: 'https://docs.google.com/document/d/1IifDYkPTNElNO3KnRilA6TmG22LpyBUfUWwoDPol8gs',
			},
			{
				type: 'SMP',
				description: 'SMP-004-049 - EM200F Turret Indexing Drive - Spur/Drive Gear Replacement',
				link: 'https://docs.google.com/document/d/1sWd9BjwjUzF1P1YCWC3ecvKNYseEJLO3CX2WxxyJ_wg',
			},
			{
				type: 'SMP',
				description: 'SMP-004-050 - EM200F Turret Indexing Drive - Shock Absorber Replacement',
				link: 'https://docs.google.com/document/d/1O_3zCn2QJhGvzcfHyhR9atfIFi6MPf550D0J_4l5pZA',
			},
			{
				type: 'SMP',
				description: 'SMP-004-051 - EM200F Turret Indexing Drive - Bushing SF286-60 Replacement',
				link: 'https://docs.google.com/document/d/1y9OMPdPg75wz8st5imbB1YuvqcTTb9Lbmb8Zjoaephs',
			},
			{
				type: 'SMP',
				description: 'SMP-004-052 - EM200F Turret Indexing Drive - Hydraulic Cylinder Replacement',
				link: 'https://docs.google.com/document/d/1by_rsdlBBOYOXwsyktBkJ04btDw7oVTsnB_JoiXwxxc',
			},
			{
				type: 'SMP',
				description: 'SMP-004-053 - EM200F Turret Indexing Drive - Hydraulic Cylinder Inspection',
				link: 'https://docs.google.com/document/d/1jb8H8bTy33FwX6SLkoxndPiPhBrYXAnPyvDkE_hd9aY',
			},
			{
				type: 'SMP',
				description: 'SMP-004-054 - EM200F Turret Indexing Drive - Turret Lock Pin Replacement',
				link: 'https://docs.google.com/document/d/1Ttyd4ygb5d2-1ZLmDV5LK3NyNhiCFNjSKaKXb_ij5gs',
			},
			{
				type: 'SMP',
				description: 'SMP-004-055 - EM200F Hold Down Arm - Hydraulic Cylinder Replacement',
				link: 'https://docs.google.com/document/d/1opsdQWWt0sqE8rPsdGq_kf6rhVxjSm_3KOs8CGSVWX0',
			},
			{
				type: 'SMP',
				description: 'SMP-004-056 - EM200F Hold Down Arm - Hydraulic Cylinder Inspection',
				link: 'https://docs.google.com/document/d/1iOOcPdCZH6LREQ_MCa0jXpLZMCAuJgY5iij0Igrfjjc',
			},
			{
				type: 'SMP',
				description: 'SMP-004-057 - EM200F Hold Down Arm - Pillow Block Replacement',
				link: 'https://docs.google.com/document/d/1cwSCKrqdP2blSdAZMtAf5fAiMJ8rBKZvsWAH2U9FtrQ',
			},
			{
				type: 'SMP',
				description: 'SMP-004-058 - EM200F Hold Down Arm - Pillow Block Lubrication',
				link: 'https://docs.google.com/document/d/1JQFvNE9tIgCRAuavffDqw3ezPT5pNFlKNQR_qGza_v0',
			},
			{
				type: 'SMP',
				description: 'SMP-004-059 - EM200F Hold Down Arm - Hydraulic Motor Replacement',
				link: 'https://docs.google.com/document/d/1AbWbuSwSfxSQD98MUvrRzy4c31xwYZkKW3fS3MtE1IU',
			},
			{
				type: 'SMP',
				description: 'SMP-004-060 - EM200F Hold Down Arm - Roller Chain Replacement',
				link: 'https://docs.google.com/document/d/1DQotrKCv1bGhq8ZIUuyG_gOYnFqJPIfhtSyFYmegt1I',
			},
			{
				type: 'SMP',
				description: 'SMP-004-061 - EM200F Hold Down Arm - Roller Chain Inspection',
				link: 'https://docs.google.com/document/d/1IjZjYvMgVbNYW06rx_2fz7YcyUXy63hEFi1eRijbSVQ',
			},
			{
				type: 'SMP',
				description: 'SMP-004-062 - EM200F Hold Down Arm - Roller Chain Lubrication',
				link: 'https://docs.google.com/document/d/1bLcTbGIL7sRMaMrDh9bWkxaRfBqqEXvCsFxlBPXdxm4',
			},
			{
				type: 'SMP',
				description: 'SMP-004-063 - EM200F Hold Down Arm - Drive Sprocket 41B30 Replacement',
				link: 'https://docs.google.com/document/d/1KzNVRH276qPHgT9ACekQmFiIw3AGpiqvJyxQXBF5g1g',
			},
			{
				type: 'SMP',
				description: 'SMP-004-064 - EM200F Hold Down Arm - Driven Sprocket 41A30 Replacement',
				link: 'https://docs.google.com/document/d/1efDx0vbkziDL46gC6XBZhtqiYauXuTZUwWVdm9SfHFg',
			},
			{
				type: 'SMP',
				description: 'SMP-004-065 - EM200F Hold Down Arm - Bearing Replacement',
				link: 'https://docs.google.com/document/d/1whkMqHsAWsDDIJWd-dh1cwHeBcZmeRrqqk0QfwlydZU',
			},
			{
				type: 'SMP',
				description: 'SMP-004-066 - EM200F Spindle-Inching Drive - Hydraulic Motor Replacement',
				link: 'https://docs.google.com/document/d/1J2jFmDOasNaZMpSyjvRQlMVpF7VDwpjv5N4as8cfwaw',
			},
			{
				type: 'SMP',
				description: 'SMP-004-067 - EM200F Spindle-Inching Drive - Chain Replacement',
				link: 'https://docs.google.com/document/d/1YppqSUXy3rhbNV2Guae3Teiapg3e2SRZ_BddRXerDP0',
			},
			{
				type: 'SMP',
				description: 'SMP-004-068 - EM200F Spindle-Inching Drive - Chain Lubrication',
				link: 'https://docs.google.com/document/d/1QiwP_0v1W9HC9m5hvS53syBMvUVocqcQsJ7t8HpwHHI',
			},
			{
				type: 'SMP',
				description: 'SMP-004-069 - EM200F Spindle-Inching Drive - Driver Sprocket Replacement',
				link: 'https://docs.google.com/document/d/1m61PUMzyd_Fb6bzpuDGSZ9UPN-e9vRxwo2ADbY0h3iU',
			},
			{
				type: 'SMP',
				description: 'SMP-004-070 - EM200F Spindle-Inching Drive - Driven Sprocket Replacement',
				link: 'https://docs.google.com/document/d/1T0uS3yoZKJX8gvM58srqBSC-OMLiA0KpeoTq1IqpqHI',
			},
			{
				type: 'SMP',
				description: 'SMP-004-071 - EM200F Spindle-Inching Drive - Taper Roller Bearing and Oil Seal Replacement',
				link: 'https://docs.google.com/document/d/1GyNyqdDLy-kZmNt3LabikW70jL158Yn6SPeZzeMBF_c',
			},
			{
				type: 'SMP',
				description: 'SMP-004-072 - EM200F Spindle-Inching Drive - Taper Roller Bearing Lubrication',
				link: 'https://docs.google.com/document/d/17bc5qVHljX3AjReAxCegkmIvLvXwSOa7BifKYRZTWRk',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Hydraulic Cylinder Replacement',
				link: 'https://docs.google.com/document/d/184iIalct0DnFfTHCLWwrPX7NqeIoNP72tR2xDey-UWk',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Hydraulic Cylinder Inspection',
				link: 'https://docs.google.com/document/d/16TepWaN_17X5xVlb_4MNSs7VMQ3SGOViNBLCvBS6hdc',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Front and Rear Spindle Cam Replacement',
				link: 'https://docs.google.com/document/d/1g2JVbC6c9N-s0Z74V0nq0v_UcqTS4TwnlQ5cx9r2kdo',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Front and Rear Spindle Cam Lubrication',
				link: 'https://docs.google.com/document/d/1Ves6dZsB6dkqiXgZ0YdV5LDeU37wbkxLd8_fgDudWlc',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Bushing Replacement',
				link: 'https://docs.google.com/document/d/11VqPulhE8RM6QJ1KDwOeZrKf57XuBxOboHC_Eh14r-8',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Thrust Bearing Replacement',
				link: 'https://docs.google.com/document/d/1Oc5OhXffwxve3uIGEoLShi15DMRoBRzxHbuws2sZye0',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Thrust Bearing Lubrication',
				link: 'https://docs.google.com/document/d/1SMfHBgfMt3ugpciZEIGztv3UhKqOEXzCqrbIQIVNbZo',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Reel Arm Pad Replacement',
				link: 'https://docs.google.com/document/d/1IsXJIJujQftpR-O5a_rZQyO2Y8n5COE9pz1Aj2nV7D4',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Spider Guide Block Replacement',
				link: 'https://docs.google.com/document/d/1Kwwh-AElNeZppqu1zJI9oiT41qnuVmEJ34Sy8jxydRk',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Cam Arm Replacement',
				link: 'https://docs.google.com/document/d/1TShPVh3N4_SOQXXea5bu9vgiFQhWs0qH3nL6WeLAgiE',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Spider Replacement',
				link: 'https://docs.google.com/document/d/11qfyv7wyjEtz7vdfwoJRE-iIsjyaQfGl5Oppn4tfbb4',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Air Brake - Friction Facing Replacement',
				link: 'https://docs.google.com/document/d/1K6fOGkcEIlBJMcQJ-WXKUkEhDVR-YZBACPEADswOFZ8',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Air Brake - O-Ring Replacement',
				link: 'https://docs.google.com/document/d/1P7EW5Jv6tVhnNbMvXFEsdkXrxHxALDVGX0J9sfHMJl0',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - CPU Processor Replacement',
				link: 'https://docs.google.com/document/d/1rlrf0qimmV6YclKNtABuT4wC_ZpKAE0dwzNVsBpgpx4',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - CPU Processor Battery Replacement',
				link: 'https://docs.google.com/document/d/1o-BrP390y6AB5ugL2z1-Y1WCCTerSvKK6iSMOdKO6UU',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - SOLA Power Supply Replacement',
				link: 'https://docs.google.com/document/d/1ShC1meVDMkY1FXxQuPXlslJCB7a-uZdiTBWdtam97ts',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1746-IA16 Input Module Replacement',
				link: 'https://docs.google.com/document/d/1WZkDREgtbSSko4mmTnJPP7NyDYmRTWtzWm6rYIaJnPQ',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1746-OA16 Output Module Replacement',
				link: 'https://docs.google.com/document/d/1nKCAi-1Va67Zqc41pU5KZEHAiVi70VjfPv-E4H58LR8',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1771-P7 Power Supply Replacement',
				link: 'https://docs.google.com/document/d/1EczrOs1b57gm4IJ6Kl7Aj9ZnECARPb-qW6uP0xr2Djs',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1785-L30B Programmable Controller Replacement',
				link: 'https://docs.google.com/document/d/1URoUZDJCf7Lki-XQDiP9M4dFuMwkEWi-Fw1Aa2OWD7I',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1785-L30B Programmable Controller Memory Module Replacement',
				link: 'https://docs.google.com/document/d/1-iy7aJOh5p-cjquDLQCgSnrmLQJ3AClvAe7vwG4WeJs',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1771-IAD Input Module Replacement',
				link: 'https://docs.google.com/document/d/1fqanwg01vViQUR_Mrc_QhZlmsOAlG1Z1ejXHzk0Xs-A',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1771-OAD Output Module Replacement',
				link: 'https://docs.google.com/document/d/1hBzrcv1J-nwaI0kQcKd7v30TJXVrdy9Ufzwc0vnnub8',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1771-OAD Output Module Fuse Replacement',
				link: 'https://docs.google.com/document/d/1_GmH918mbnQzCJfepvyY5rXApdDAkwN_xR8Wa3SDA2s',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1771-P4S Power Supply Replacement',
				link: 'https://docs.google.com/document/d/14a7sT8TMAhFmd3R-AcNsqdLJ9f2P21Z4rGjuM_vb8Wo',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Safety Perimeter - Light Curtain Replacement',
				link: 'https://docs.google.com/document/d/12U8YKWKfcebyI0Ov-EnavT1_0pd_WkHIXGdHNya2kkM',
			},
		],
	},

	{
		name: 'EM539A Cab Welder Folder Station',
		documents: [
			{
				type: 'Ledger',
				description: 'EM539A Cab Welder Folder Station Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1l-DXHotixLv2xmaIkLNXeAuOeoaDh01b5g6nzIcfCk0/edit#gid=226825339',
			},
		],
	},

	{
		name: 'New Power Rail',
		documents: [
			{
				type: 'Ledger',
				description: 'New Power Rail Ledger',
				link: 'https://docs.google.com/spreadsheets/d/11XCmpcffCt9UNvft8Nu70FzP9xEdsiitXxaMARQdcMQ/edit#gid=1372949190',
			},

			{
				type: 'SMP',
				description: 'SMP-1 - Electrical Cabinet Clean and Inspect',
				link: 'https://docs.google.com/document/d/1HqgCwfSzkSrwDpV0OB-r2BBf2Ceg3edeWdUUTrkvxkw/edit',
				//All New Power Rail SMPs are locked
			},

			{
				type: 'SMP',
				description: 'SMP-2 - Banner Photocell Switch Clean and Inspect',
				link: 'https://docs.google.com/document/d/1l962i7fl078m8CeWRRysSzjCYLFXjhqHXyq9BXMWjvY/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-3 - Potter-Brumfield Circuit Breaker Replacement',
				link: 'https://docs.google.com/document/d/1GE2k1ERy6P0t2sUu4nWEpURsUG8SUpnKMcJdWs5KgpQ/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-4 - Allen Bradley Contactor Replacement',
				link: 'https://docs.google.com/document/d/1ZHsBsygSkTcfGKvJV17pVcscYAFXZGIUNZpijQTRbx8/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-5 - FlexCore Current Transformer Replacement',
				link: 'https://docs.google.com/document/d/1asHdI8KN0uXv1OUvuc9fpz44siQ4kbg0rGKi9I_UAkQ/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-6 - Ohio Semitronics Kilowatt Transducer Replacement',
				link: 'https://docs.google.com/document/d/1QwPqJe30cAXW30-KL3Qk6vJZUbqpDiiFXYtW--20CfY/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-7 - Allen Bradley Pilot Light Replacement',
				link: 'https://docs.google.com/document/d/1jzZoNcI_q4uhftyv8CaYT1T1B10iPogAMKTa42oBub0/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-8 - Allen Bradley Selector Switch Replacement',
				link: 'https://docs.google.com/document/d/1EBJqLMNuVuDYM9EfH7YVe9O-gpkfG_XN4DkPb0Upsvw/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-9 - Sonalert Alarm Horn Replacement',
				link: 'https://docs.google.com/document/d/1O00WUTE34V3TZRED_WaMJOBfohYCYBvSLF8wHWJOUXA/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-10 - Banner Photocell Switch Replacement',
				link: 'https://docs.google.com/document/d/1PHHyxgM8zG3m2-ZTvRP8efBjxaEQg4DJEDNmkpIO4pI/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-11 - Gas Supply Hose Clean and Inspect',
				link: 'https://docs.google.com/document/d/1gHgyKv9Xd2SD-1oSbl8JXYIui8Y56FM-9YcwOT5SWHg/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-12 - Gas Supply Hose Trolley Clean and Inspect',
				link: 'https://docs.google.com/document/d/1xd2dWzbdR1hEf7qwT5Y5L8inrIgCjjRQxSWGnsYxesc/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-13 - Transfer Trolley Clean and Inspect',
				link: 'https://docs.google.com/document/d/171txVsVAkBhUrU_begjSHeAAR5NNJUKZyfcoJIsl_Dw/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-14 - ARO Balancer Clean and Inspect',
				link: 'https://docs.google.com/document/d/1K1wJQDChb_IFHhLqWA9QD0ye42Xr4o9gR6Bme09eUEY/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-15 - Automation Head Clean and Inspect',
				link: 'https://docs.google.com/document/d/1hCA0dKHNjf3CQYK3YLXH9mvgDAkLIBBNllvdXt9Il5E/edit',
			},

			{
				type: 'SSD',
				description: 'Banner Photocell Switch Quick Start Guide',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/New_Power_Rail/documentation/SM2A312DQD.pdf',
			},

			{
				type: 'SSD',
				description: 'Flex-Core Current Transformer Data Sheet',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/New_Power_Rail/documentation/189.pdf',
			},

			{
				type: 'SSD',
				description: 'Ohio Semitronics PC5 Series Watt Transducer Operation and Calibration Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/New_Power_Rail/documentation/PC4%20and%20PC5%20Op%20and%20Cal.pdf',
			},

			{
				type: 'SSD',
				description: 'Potter & Brumfield Circuit Breaker Data Sheet',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/New_Power_Rail/documentation/ENG_DS_1308242_w6w9_1115.pdf',
			},
		],
	},

	{
		name: 'VMAX Auto Drum Glue',
		documents: [
			{
				type: 'Ledger',
				description: 'VMAX Auto Drum Glue Ledger',
				link: 'https://docs.google.com/spreadsheets/d/18lRxZeDJo6h1uyHuq0wiqth2Ff303-TPcpPK0OmOv9s/edit#gid=1372949190',
			},

			{
				type: 'Manual',
				description: 'Operation and Maintenance Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Drum_Glue/manuals/Whirlpool%20Auto%20Drum%20Glue%20-%20Operation%20and%20Maintenance%20Manual.pdf',
			},

			{
				type: 'SMP',
				description: 'SMP-1 - Vertical Chain Conveyor - SEW Eurodrive Gearmotor Replacement',
				link: 'https://docs.google.com/document/d/1Iu2oMunsxxSG8uZ003LFQX5oTYbpLYVhFhrYqbSmSSc/edit',
				//All Auto Drum Glue SMPs are locked
			},

			{
				type: 'SMP',
				description: 'SMP-2 - Vertical Chain Conveyor - Roller Chain Tensioning',
				link: 'https://docs.google.com/document/d/1htog3OOiMYtaArDoibwrzWWHkKSVJ8qw3JV0zPjUqp4/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-3 - Vertical Chain Conveyor - Roller Chain Replacement',
				link: 'https://docs.google.com/document/d/1evKYXTAA7qRfWTUGdKpqlxY3YnKK4TrUw77oEEpMg_g/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-4 - Vertical Chain Conveyor - Side Rail Replacement',
				link: 'https://docs.google.com/document/d/1KqiD-J4q2nq96N5wsbZVeHYl_U2ZxFX7O2wbI-1M27o/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-5 - Drum Rotate Mechanism - Glue Application Lift Cylinder Replacement',
				link: 'https://docs.google.com/document/d/1M7M60wDrJ9XAI2cKm7o6ZYFMpbL1xA3qM0_LHf9zex0/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-6 - Drum Rotate Mechanism - Drum Rotate Actuation Cylinder Replacement',
				link: 'https://docs.google.com/document/d/1Kogah8f-xPR-h9ji9ASc8ccTK9sNoKIeNzBMai0uje0/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-7 - Drum Rotate Mechanism - Drum Rotate Drive Replacement',
				link: 'https://docs.google.com/document/d/18PYIk82MPBVGmX_lTq-NqwnJZ0SXkChtc8bXMDPuVaM/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-8 - Drum Rotate Mechanism - Drum Rotate Chain Tensioning',
				link: 'https://docs.google.com/document/d/1ruNjHsdkj9cUUU-8I_i7-JVerHIg-Hz4AyZ46vIuC98/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-9 - Drum Rotate Mechanism - Drum Rotate Chain Replacement',
				link: 'https://docs.google.com/document/d/15KC5yHFwM4y5ZKrmv0QNN83SmNdnFBYTNtxdtxWE0l4/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-10 - Drum Rotate Mechanism - Drum Rotate Actuation Roller Replacement',
				link: 'https://docs.google.com/document/d/1es0WnMUmqQ99HnTIN8j1JRHBDZJX2aGoNqc37nmCugk/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-11 - Glue Vertical Shift Mechanism - Ball Bushing and Guide Rod Replacement',
				link: 'https://docs.google.com/document/d/1nwwkSUMynqPxzEZdr0CxRUqkawwgYGLouhcEe5Kej6E/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-12 - Glue Vertical Shift Mechanism - Lift Cylinder Replacement',
				link: 'https://docs.google.com/document/d/1dyw8GbdYxKaFIiGAUWL0HHv04RGypCL09pA_uw0OYA4/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-13 - Glue Vertical Shift Mechanism - Guide Roller Replacement',
				link: 'https://docs.google.com/document/d/1oFOv6vRorpuWtmWn58aNJuCixFkLObzMlvJpKDiJpRo/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-14 - Drum Rotate Crowder Mechanism - Crowder Linear Slide Replacement',
				link: 'https://docs.google.com/document/d/1vtKnGqc9YYaFiuFCMoLY7SHZsa3F3YymAf2G6Pa93NQ/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-15 - Drum Rotate Crowder Mechanism - Crowder Bar Replacement',
				link: 'https://docs.google.com/document/d/19iELVgLhJgPK5DcZtjtuO-1kvKZ7LgJfG8xpf0O1Zxo/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-16 - Queue Eject Unit - Guide Roller Replacement',
				link: 'https://docs.google.com/document/d/1JdlPSjBkGWYWRpUJxx-zQPdT25is1sqOMQTy5u-FzJY/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-17 - Queue Eject Unit - Linear Slide Replacement',
				link: 'https://docs.google.com/document/d/1TRqaM0ieyWtY7mSjeiM2MFW9TrdWAQSbYUaFUfWbf_4/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-18 - Felt Crowder - Linear Slide Replacement',
				link: 'https://docs.google.com/document/d/1BybzrquXK-RS8mFIe1DOO99r2cVVr3WQ5FtMnpBPb_Y/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-19 - Felt Crowder - Back Stop Replacement',
				link: 'https://docs.google.com/document/d/1fFC8JFADa-_b_JBxdnkfShQ-92J96aR0KthQZMPqnjc/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-20 - Felt Eject Unit - Guide Roller Replacement',
				link: 'https://docs.google.com/document/d/1bhwbjyUIm-x0AtcoKmv1OCEjKApXtiKbZyfesOFOkPY/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-21 - Felt Eject Unit - Rotary Actuator Replacement',
				link: 'https://docs.google.com/document/d/18PAAYJZJOtXlRIAaNFY9eMbCuOcxqZvjkQxrHjLZq94/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-22 - Felt Eject Unit - Linear Slide Replacement',
				link: 'https://docs.google.com/document/d/1FpzxLFLnjRF9Wnm1jnG5WPsM7yjYhTg5sJDyCMkVcxA/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-23 - Upper Stop Mechanism - Linear Slide Replacement',
				link: 'https://docs.google.com/document/d/1GFWaDXXjH5Z3zzmgrEplqCZ6SDSemv4eY9X_RHJ32CQ/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-24 - Upper Stop Mechanism - Drum Stopper Replacement',
				link: 'https://docs.google.com/document/d/1JuXrICW8QaN-5Mv0Qgf3SLnu_rQeMKyAiNJpwaY8tNk/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-25 - Pneumatic Maintenance - Particulate Filter Change',
				link: 'https://docs.google.com/document/d/1fbNoPOy0JRXRSPPxCNs2EOFaCYKmwEWxFQMVePPr4ic/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-26 - Pneumatic Maintenance - Coalescing Filter Change',
				link: 'https://docs.google.com/document/d/1iR0nskKATCRwPQtw5qkl9t6eydx6rosj1kleF0DVEwY/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-27 - Pneumatic Maintenance - Spool Valve Replacement',
				link: 'https://docs.google.com/document/d/1rnYWi3wPF89Ip-RV3neehR8rtmAvr5dLYPAHReEhD2E/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-28 - Pneumatic Maintenance - Soft Start Solenoid Valve Replacement',
				link: 'https://docs.google.com/document/d/1O_rp8l1oHmLf6EQILFHuhEYBv_I-nMMC11H-Ii92rtw/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-29 - Electrical Maintenance - Allen Bradley Power Supply Replacement',
				link: 'https://docs.google.com/document/d/1TTm7piUeW672Zk1R3yzltWmQ3Fo9397IgcE72fbF_os/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-30 - Electrical Maintenance - Allen Bradley Safety Input Module Replacement',
				link: 'https://docs.google.com/document/d/11xCh0pxws4zmw1to-zD84qdN03hizCHPFMg59ctOeJk/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-31 - Electrical Maintenance - Allen Bradley Safety Output Module Replacement',
				link: 'https://docs.google.com/document/d/18ffwSx75MKcgZzFBHRvPCbT4C_TyhCJxAshTNcnvOrI/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-32 - Electrical Maintenance - Allen Bradley VFD Replacement',
				link: 'https://docs.google.com/document/d/1GT6FB38VwmRR0WerIMRo6KSZEnJIdZLuKSVGFPFL4vs/edit',
			},

			{
				type: 'SSD',
				description: 'Allen-Bradley SensaGuard Installation Instructions',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Drum_Glue/Documentation/440n-in018-en.pdf',
			},

			{
				type: 'SSD',
				description: 'Banner Q4X Stainless Steel Laser Sensor Quick Start Guide',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Drum_Glue/Documentation/181484.pdf',
			},

			{
				type: 'SSD',
				description: 'Keyence Clamp-on Flow Sensor Instruction Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Drum_Glue/Documentation/FD-Q_IM_96M13515_GB_WW_1086-3.pdf',
			},

			{
				type: 'SSD',
				description: "Balluff BMP-01-EL1PP User's Guide",
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Drum_Glue/Documentation/MAN_BMP_01_EL1PP_1A_00_P_X_L18_DOK_939733_02_000.pdf',
			},
		],
	},

	{
		name: 'VMAX Auto Screw Feed Robot System',
		documents: [
			{
				type: 'Ledger',
				description: 'VMAX Auto Screw Feed Robot System Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1mwfGEym_7rzVmCrsIcNuEx3mD-aa2kMY26YnXL-MQZU/edit#gid=1670184335',
			},

			{
				type: 'Manual',
				description: 'Operation and Maintenance Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Screw_Feed_Robot_System/manuals/Auto%20Screw%20Feed%20Robot%20System%20Operation%20and%20Maintenance%20Manual.pdf',
			},

			{
				type: 'SSD',
				description: 'Balluff Tower Light Datasheet',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Screw_Feed_Robot_System/Documentation/0c7b9f58c05f26c2823b5f4b99ff75dc.pdf',
			},

			{
				type: 'SSD',
				description: 'Allen-Bradley GuardShield Safety Light Curtain User Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Screw_Feed_Robot_System/Documentation/450l-um001_-en-p.pdf',
			},

			{
				type: 'SSD',
				description: 'DTI 5000 Auto Feed Screwdriver Systems',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Screw_Feed_Robot_System/Documentation/DTI_5000.pdf',
			},

			{
				type: 'SSD',
				description: 'Nordson Sealant Equipment 245 1-Part Tip-Seal Kiss Series Datasheet',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Screw_Feed_Robot_System/Documentation/NSL5991-245-1-Part-Tip-Seal-Kiss-Series-Data-Sheet-EN.pdf',
			},

			{
				type: 'SSD',
				description: 'YRC1000 Alarm Codes (Major Alarms)',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Screw_Feed_Robot_System/Documentation/YRC1000-Alarm-Codes.pdf',
			},

			{
				type: 'SSD',
				description: 'YRC1000 Instructions',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Screw_Feed_Robot_System/Documentation/YRC1000-Instructions-PN-178642-1CD-Rev12.pdf',
			},

			{
				type: 'SSD',
				description: 'YRC1000 I/O Jog Operation In Play Mode',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Screw_Feed_Robot_System/Documentation/YRC1000-Instructions-for-IO-Jog-Operation.pdf',
			},

			{
				type: 'SSD',
				description: 'YRC1000 Options - Instructions for JANCD-AEW02-E Board',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Screw_Feed_Robot_System/Documentation/YRC1000-Options-Instructions(3-Channel-analog-output).pdf',
			},

			{
				type: 'SSD',
				description: 'YRC1000 Maintenance Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Screw_Feed_Robot_System/Documentation/YRC1000-maintenance-manual.pdf',
			},

			{
				type: 'SSD',
				description: 'Motoman-HC10 Instructions',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Screw_Feed_Robot_System/Documentation/Yaskawa-HC10-Manipulator-Instructions.pdf',
			},

			{
				type: 'SSD',
				description: 'Motoman-HC10 Supplemental Instructions',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Auto_Screw_Feed_Robot_System/Documentation/Yaskawa-HC10-Splumental-Instructions.pdf',
			},
		],
	},

	{
		name: 'VMAX Cab Delivery',
		documents: [
			{
				type: 'Ledger',
				description: 'VMAX Cab Delivery Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1YgrY6LQtvfzQN6jtaqOdkLJ2FnYuDk3aMH9Utlrl9dM/edit#gid=1372949190',
			},

			{
				type: 'Manual',
				description: 'Operation and Maintenance Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Cab_Delivery/manuals/Whirlpool%20Cab%20Delivery%20Operation%20and%20Maintenance%20Manual.pdf',
			},

			{
				type: 'SSD',
				description: 'Banner TL50 Tower Light Datasheet',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Cab_Delivery/documentation/142406.pdf',
			},

			{
				type: 'SSD',
				description: 'Allen-Bradley PanelView Plus 7 Performance Terminals User Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Cab_Delivery/documentation/2711p-um008_-en-p.pdf',
			},

			{
				type: 'SSD',
				description: "Roach Conveyors Owner's Manual - Tech Handbook",
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Cab_Delivery/documentation/Belt_Conveyors.pdf',
			},

			{
				type: 'SSD',
				description: "Roach Continuous Vertical Conveyor Owner's Manual - Tech Handbook",
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Cab_Delivery/documentation/cvc.pdf',
			},

			{
				type: 'SSD',
				description: 'Boston Worm Gear Speed Reducers Installation and Operation Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Cab_Delivery/documentation/p-3009-bg.pdf',
			},
		],
	},

	{
		name: 'VMAX Cab Mat (EM200F)',
		documents: [
			{
				type: 'Ledger',
				description: '2018 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1zZsmZxHbhczh8XzRBgubaWOAp2DgDkJ4ExuX49L36ZM/edit?ts=5c2e06c4#gid=657824894',
				//locked
			},

			{
				type: 'Ledger',
				description: '2019 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/13HhgZF05mZ6UgsECyWUVJg6-ytMUJG44ahpFSfBiAw4/edit#gid=1204009047',
			},

			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Uncoiler Turret Main Bearing Replacement',
				link: 'https://docs.google.com/document/d/1mLqS2V0kV_Xt4pnzF16aG-5foKUsJwe-FTnxk7bFniI/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-030 - EM200F Coil Car - Hydraulic Cylinder Inspection',
				link: 'https://docs.google.com/document/d/1RjR0GapRuua3RDbNsH2gkADggr16_Or3WBu7gnNQ4mI/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-031 - EM200F Coil Car - Hydraulic Cylinder Replacement',
				link: 'https://docs.google.com/document/d/1-bYY0B8Omujy8VTatLfWGI_QoG9Nk9qY3WEcPEUq4SQ/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-032 - EM200F Coil Car - Pinion Gear Replacement',
				link: 'https://docs.google.com/document/d/16rkK--XfZ_pbP8qjeY5r5srKgAAAxEGkr-xdIlGjLfc/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-033 - EM200F Coil Car - Leveling Racks Bearing Replacement',
				link: 'https://docs.google.com/document/d/1tNharvoEa-j_U3pURLAd_F-V77JoT6Fpcyia0xpbsxw/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-034 - EM200F Coil Car - Hydraulic Motor Replacement',
				link: 'https://docs.google.com/document/d/1IY0zxfaNW26-U18zwLbz2w5CHwK07jiQT22IwBKtANU/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-035 - EM200F Coil Car - Drive Sprocket Replacement',
				link: 'https://docs.google.com/document/d/1pY3oYR1MyjTx_0vOzunR42N65120Kny-Af5pDXLeMdg/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-036 - EM200F Coil Car - Drive Chain Replacement',
				link: 'https://docs.google.com/document/d/1B2wW3mLZJJv1TkRRLpPDpY2pXwPnqVV6cSi9B5ziJQU/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-037 - EM200F Coil Car - Drive Chain Lubrication',
				link: 'https://docs.google.com/document/d/1cJwvPD0ybJ2K9_v5ZvKO16SMsdJguroZIeu5XjYIbIo/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-038 - EM200F Coil Car - Wheel Bearing Lubrication',
				link: 'https://docs.google.com/document/d/1GARV6ksa8OOHIMZUYb1RUbRAgyladTtpK8cCJ10syEU/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-039 - EM200F Coil Car - Driven Sprocket(s) Replacement',
				link: 'https://docs.google.com/document/d/1SSA20XQDzDat04hOYz1JQU_xY7oP45aXy9-0LHZs1TA/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-040 - EM200F Coil Car - Idler Chain Replacement',
				link: 'https://docs.google.com/document/d/1Gyi6fYacjuzzEaO57jowCGhdrjF4E8d_27ih5Mmv0s4/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-041 - EM200F Coil Car - Drive/Idler Shaft Bearing Replacement',
				link: 'https://docs.google.com/document/d/1XZi3cnCzBV6XB_IxMYkEP3WAM0wdQVF_cphf2tDJNKQ/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-042 - EM200F Coil Car - Idler Chain Lubrication',
				link: 'https://docs.google.com/document/d/1mpH_F4slPBjYJoZDKv-5X0cHpGEwjbyiB9JZAn317JA/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-043 - EM200F Coil Car - Leveling Racks Lubrication',
				link: 'https://docs.google.com/document/d/1RK5PLmhHiQc_vmYYVjj4nWypcv5EMGYfdu_aOaRRj6s/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-044 - EM200F Coil Car - Leveling Racks Replacement',
				link: 'https://docs.google.com/document/d/17vi4phJUI43usizNSZkB0csB-MmPe05ML99QeNVnjgo/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-045 - EM200F Coil Car - Leveling Rack Bushing Replacement',
				link: 'https://docs.google.com/document/d/1d-EgY8XQwW2CYMcuIhyrcJMAPFwlwaPctMhSFJ9iPqc/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-046 - EM200F Coil Car - Drive/Idler Shaft Replacement',
				link: 'https://docs.google.com/document/d/16_j14x6zCH_uVzOAo4vKcp8W74ubbUxAu4u-EQ7QxHQ/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-047 - EM200F Turret Indexing Drive - Hydraulic Motor Replacement',
				link: 'https://docs.google.com/document/d/18PIOpy4WqAhyauXKagQpNP2DpqZHJWNe5wBisoMlqOA/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-048 - EM200F Turret Indexing Drive - Gear Reducer Replacement',
				link: 'https://docs.google.com/document/d/1IifDYkPTNElNO3KnRilA6TmG22LpyBUfUWwoDPol8gs/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-049 - EM200F Turret Indexing Drive - Spur/Drive Gear Replacement',
				link: 'https://docs.google.com/document/d/1sWd9BjwjUzF1P1YCWC3ecvKNYseEJLO3CX2WxxyJ_wg/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-050 - EM200F Turret Indexing Drive - Shock Absorber Replacement',
				link: 'https://docs.google.com/document/d/1O_3zCn2QJhGvzcfHyhR9atfIFi6MPf550D0J_4l5pZA/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-051 - EM200F Turret Indexing Drive - Bushing SF286-60 Replacement',
				link: 'https://docs.google.com/document/d/1y9OMPdPg75wz8st5imbB1YuvqcTTb9Lbmb8Zjoaephs/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-052 - EM200F Turret Indexing Drive - Hydraulic Cylinder Replacement',
				link: 'https://docs.google.com/document/d/1by_rsdlBBOYOXwsyktBkJ04btDw7oVTsnB_JoiXwxxc/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-053 - EM200F Turret Indexing Drive - Hydraulic Cylinder Inspection',
				link: 'https://docs.google.com/document/d/1jb8H8bTy33FwX6SLkoxndPiPhBrYXAnPyvDkE_hd9aY/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-054 - EM200F Turret Indexing Drive - Turret Lock Pin Replacement',
				link: 'https://docs.google.com/document/d/1Ttyd4ygb5d2-1ZLmDV5LK3NyNhiCFNjSKaKXb_ij5gs/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-055 - EM200F Hold Down Arm - Hydraulic Cylinder Replacement',
				link: 'https://docs.google.com/document/d/1opsdQWWt0sqE8rPsdGq_kf6rhVxjSm_3KOs8CGSVWX0/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-056 - EM200F Hold Down Arm - Hydraulic Cylinder Inspection',
				link: 'https://docs.google.com/document/d/1iOOcPdCZH6LREQ_MCa0jXpLZMCAuJgY5iij0Igrfjjc/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-057 - EM200F Hold Down Arm - Pillow Block Replacement',
				link: 'https://docs.google.com/document/d/1cwSCKrqdP2blSdAZMtAf5fAiMJ8rBKZvsWAH2U9FtrQ/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-058 - EM200F Hold Down Arm - Pillow Block Lubrication',
				link: 'https://docs.google.com/document/d/1JQFvNE9tIgCRAuavffDqw3ezPT5pNFlKNQR_qGza_v0/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-059 - EM200F Hold Down Arm - Hydraulic Motor Replacement',
				link: 'https://docs.google.com/document/d/1AbWbuSwSfxSQD98MUvrRzy4c31xwYZkKW3fS3MtE1IU/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-060 - EM200F Hold Down Arm - Roller Chain Replacement',
				link: 'https://docs.google.com/document/d/1DQotrKCv1bGhq8ZIUuyG_gOYnFqJPIfhtSyFYmegt1I/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-061 - EM200F Hold Down Arm - Roller Chain Inspection',
				link: 'https://docs.google.com/document/d/1IjZjYvMgVbNYW06rx_2fz7YcyUXy63hEFi1eRijbSVQ/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-062 - EM200F Hold Down Arm - Roller Chain Lubrication',
				link: 'https://docs.google.com/document/d/1bLcTbGIL7sRMaMrDh9bWkxaRfBqqEXvCsFxlBPXdxm4/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-063 - EM200F Hold Down Arm - Drive Sprocket 41B30 Replacement',
				link: 'https://docs.google.com/document/d/1KzNVRH276qPHgT9ACekQmFiIw3AGpiqvJyxQXBF5g1g/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-064 - EM200F Hold Down Arm - Driven Sprocket 41A30 Replacement',
				link: 'https://docs.google.com/document/d/1efDx0vbkziDL46gC6XBZhtqiYauXuTZUwWVdm9SfHFg/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-065 - EM200F Hold Down Arm - Bearing Replacement',
				link: 'https://docs.google.com/document/d/1whkMqHsAWsDDIJWd-dh1cwHeBcZmeRrqqk0QfwlydZU/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-066 - EM200F Spindle-Inching Drive - Hydraulic Motor Replacement',
				link: 'https://docs.google.com/document/d/1J2jFmDOasNaZMpSyjvRQlMVpF7VDwpjv5N4as8cfwaw/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-067 - EM200F Spindle-Inching Drive - Chain Replacement',
				link: 'https://docs.google.com/document/d/1YppqSUXy3rhbNV2Guae3Teiapg3e2SRZ_BddRXerDP0/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-068 - EM200F Spindle-Inching Drive - Chain Lubrication',
				link: 'https://docs.google.com/document/d/1QiwP_0v1W9HC9m5hvS53syBMvUVocqcQsJ7t8HpwHHI/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-069 - EM200F Spindle-Inching Drive - Driver Sprocket Replacement',
				link: 'https://docs.google.com/document/d/1m61PUMzyd_Fb6bzpuDGSZ9UPN-e9vRxwo2ADbY0h3iU/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-070 - EM200F Spindle-Inching Drive - Driven Sprocket Replacement',
				link: 'https://docs.google.com/document/d/1T0uS3yoZKJX8gvM58srqBSC-OMLiA0KpeoTq1IqpqHI/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-071 - EM200F Spindle-Inching Drive - Taper Roller Bearing and Oil Seal Replacement',
				link: 'https://docs.google.com/document/d/1GyNyqdDLy-kZmNt3LabikW70jL158Yn6SPeZzeMBF_c/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-004-072 - EM200F Spindle-Inching Drive - Taper Roller Bearing Lubrication',
				link: 'https://docs.google.com/document/d/17bc5qVHljX3AjReAxCegkmIvLvXwSOa7BifKYRZTWRk/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Hydraulic Cylinder Replacement',
				link: 'https://docs.google.com/document/d/184iIalct0DnFfTHCLWwrPX7NqeIoNP72tR2xDey-UWk/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Hydraulic Cylinder Inspection',
				link: 'https://docs.google.com/document/d/16TepWaN_17X5xVlb_4MNSs7VMQ3SGOViNBLCvBS6hdc/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Front and Rear Spindle Cam Replacement',
				link: 'https://docs.google.com/document/d/1g2JVbC6c9N-s0Z74V0nq0v_UcqTS4TwnlQ5cx9r2kdo/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Front and Rear Spindle Cam Lubrication',
				link: 'https://docs.google.com/document/d/1Ves6dZsB6dkqiXgZ0YdV5LDeU37wbkxLd8_fgDudWlc/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Bushing Replacement',
				link: 'https://docs.google.com/document/d/11VqPulhE8RM6QJ1KDwOeZrKf57XuBxOboHC_Eh14r-8/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Thrust Bearing Replacement',
				link: 'https://docs.google.com/document/d/1Oc5OhXffwxve3uIGEoLShi15DMRoBRzxHbuws2sZye0/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Thrust Bearing Lubrication',
				link: 'https://docs.google.com/document/d/1SMfHBgfMt3ugpciZEIGztv3UhKqOEXzCqrbIQIVNbZo/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Reel Arm Pad Replacement',
				link: 'https://docs.google.com/document/d/1IsXJIJujQftpR-O5a_rZQyO2Y8n5COE9pz1Aj2nV7D4/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Spider Guide Block Replacement',
				link: 'https://docs.google.com/document/d/1Kwwh-AElNeZppqu1zJI9oiT41qnuVmEJ34Sy8jxydRk/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Cam Arm Replacement',
				link: 'https://docs.google.com/document/d/1TShPVh3N4_SOQXXea5bu9vgiFQhWs0qH3nL6WeLAgiE/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Spider Replacement',
				link: 'https://docs.google.com/document/d/11qfyv7wyjEtz7vdfwoJRE-iIsjyaQfGl5Oppn4tfbb4/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Air Brake - Friction Facing Replacement',
				link: 'https://docs.google.com/document/d/1K6fOGkcEIlBJMcQJ-WXKUkEhDVR-YZBACPEADswOFZ8/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - EM200F Spindle-Inching Drive - Air Brake - O-Ring Replacement',
				link: 'https://docs.google.com/document/d/1P7EW5Jv6tVhnNbMvXFEsdkXrxHxALDVGX0J9sfHMJl0/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - CPU Processor Replacement',
				link: 'https://docs.google.com/document/d/1rlrf0qimmV6YclKNtABuT4wC_ZpKAE0dwzNVsBpgpx4/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - CPU Processor Battery Replacement',
				link: 'https://docs.google.com/document/d/1o-BrP390y6AB5ugL2z1-Y1WCCTerSvKK6iSMOdKO6UU/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - SOLA Power Supply Replacement',
				link: 'https://docs.google.com/document/d/1ShC1meVDMkY1FXxQuPXlslJCB7a-uZdiTBWdtam97ts/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1746-IA16 Input Module Replacement',
				link: 'https://docs.google.com/document/d/1WZkDREgtbSSko4mmTnJPP7NyDYmRTWtzWm6rYIaJnPQ/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1746-OA16 Output Module Replacement',
				link: 'https://docs.google.com/document/d/1nKCAi-1Va67Zqc41pU5KZEHAiVi70VjfPv-E4H58LR8/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1771-P7 Power Supply Replacement',
				link: 'https://docs.google.com/document/d/1EczrOs1b57gm4IJ6Kl7Aj9ZnECARPb-qW6uP0xr2Djs/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1785-L30B Programmable Controller Replacement',
				link: 'https://docs.google.com/document/d/1URoUZDJCf7Lki-XQDiP9M4dFuMwkEWi-Fw1Aa2OWD7I/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1785-L30B Programmable Controller Memory Module Replacement',
				link: 'https://docs.google.com/document/d/1-iy7aJOh5p-cjquDLQCgSnrmLQJ3AClvAe7vwG4WeJs/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1771-IAD Input Module Replacement',
				link: 'https://docs.google.com/document/d/1fqanwg01vViQUR_Mrc_QhZlmsOAlG1Z1ejXHzk0Xs-A/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1771-OAD Output Module Replacement',
				link: 'https://docs.google.com/document/d/1hBzrcv1J-nwaI0kQcKd7v30TJXVrdy9Ufzwc0vnnub8/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1771-OAD Output Module Fuse Replacement',
				link: 'https://docs.google.com/document/d/1_GmH918mbnQzCJfepvyY5rXApdDAkwN_xR8Wa3SDA2s/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Electrical Control Panel - 1771-P4S Power Supply Replacement',
				link: 'https://docs.google.com/document/d/14a7sT8TMAhFmd3R-AcNsqdLJ9f2P21Z4rGjuM_vb8Wo/edit',
			},
			{
				type: 'SMP',
				description: 'SMP-XXX-XXX - Cab Mat Safety Perimeter - Light Curtain Replacement',
				link: 'https://docs.google.com/document/d/12U8YKWKfcebyI0Ov-EnavT1_0pd_WkHIXGdHNya2kkM/edit',
			},
		],
	},

	{
		name: 'VMAX Door Build Line',
		documents: [
			{
				description: 'VMAX Door Build Line Ledger',
				type: 'Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1uxVvpSUskSWJyAYLJ_A4EqWT_4PjD5OVWDMto-wAav0/edit#gid=1670184335',
			},
			{
				description: 'Operation and Maintenance Manual',
				type: 'Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Door_Build_Line/manuals/Whirlpool%20Door%20Build%20Operation%20and%20Maintenance%20Manual.pdf',
			},
			{
				type: 'SSD',
				description: 'Balluff Tower Light Datasheet',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Door_Build_Line/Documentation/0c7b9f58c05f26c2823b5f4b99ff75dc.pdf',
			},

			{
				type: 'SSD',
				description: 'PCF w/ PrecisionSwirl Dispense System Instructions',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Door_Build_Line/Documentation/3A2098EN-S.pdf',
			},

			{
				type: 'SSD',
				description: 'Allen-Bradley GuardShield Safety Light Curtain User Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Door_Build_Line/Documentation/450l-um001_-en-p.pdf',
			},

			{
				type: 'SSD',
				description: 'Cleco Inline Screwdrivers Service Manual PL12-1013EN',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Door_Build_Line/Documentation/PL12-1013EN.pdf',
			},

			{
				type: 'SSD',
				description: 'Accumulating Roller Chain Conveyor SRF-P 2010',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Door_Build_Line/Documentation/extra-srf-p-2010-documentation.pdf',
			},

			{
				type: 'SSD',
				description: 'MK NA KHL Model Vertical Transfer Units',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Door_Build_Line/Documentation/mk-vertical-transfer-unit.pdf',
			},

			{
				type: 'SSD',
				description: 'MK NA SRF-P 2010 Accumulating Roller Chain Conveyor',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Door_Build_Line/Documentation/technical-documentation-srf-p-2010.pdf',
			},
		],
	},

	{
		name: 'VMAX Drum Heater Box Robot',
		documents: [
			{
				type: 'Ledger',
				description: 'VMAX Drum Heater Box Robot Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1lgK7X4azLWLXX5Z6mTCObWwNPZgxdPCkCNgu-d4bRBw/edit#gid=1372949190',
			},

			{
				type: 'Manual',
				description: 'Operation and Maintenance Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Drum_Heater_Box_Robot/manuals/Whirlpool%20VMAX%20Drum%20Heater%20Box%20Robot%20Operation%20and%20Maintenance%20Manual.pdf',
			},

			{
				type: 'SSD',
				description: 'Allen-Bradley GuardShield Safety Light Curtain User Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Drum_Heater_Box_Robot/Documentation/450l-um001_-en-p.pdf',
			},

			{
				type: 'SSD',
				description: 'Banner Q4X Stainles Steel Laser Sensor Quick Start Guide',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Drum_Heater_Box_Robot/Documentation/450l-um001_-en-p.pdf',
			},

			{
				type: 'SSD',
				description: 'YRC1000 Alarm Codes (Major Alarms)',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Drum_Heater_Box_Robot/Documentation/YRC1000-Alarm-Codes.pdf',
			},

			{
				type: 'SSD',
				description: 'YRC1000 Instructions',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Drum_Heater_Box_Robot/Documentation/YRC1000-Instructions-PN-178642-1CD-Rev12.pdf',
			},

			{
				type: 'SSD',
				description: 'YRC1000 I/O Jog Operation In Play Mode',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Drum_Heater_Box_Robot/Documentation/YRC1000-Instructions-for-IO-Jog-Operation.pdf',
			},

			{
				type: 'SSD',
				description: 'YRC1000 Options - Instructions for JANCD-AEW02-E Board',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Drum_Heater_Box_Robot/Documentation/YRC1000-Options-Instructions(3-Channel-analog-output).pdf',
			},

			{
				type: 'SSD',
				description: 'YRC1000 Maintenance Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Drum_Heater_Box_Robot/Documentation/YRC1000-maintenance-manual.pdf',
			},

			{
				type: 'SSD',
				description: 'Yaskawa Motoman-GP25 Instructions',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Drum_Heater_Box_Robot/Documentation/motoman-GP25.pdf',
			},
		],
	},

	{
		name: 'VMAX Hole Punch',
		documents: [
			{
				type: 'Ledger',
				description: 'VMAX Hole Punch Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1vJJ1LBPTQv3wKvy5oD7FQDuwOMBgsarXGRfGncnOsfQ/edit#gid=1670184335',
			},

			{
				type: 'Manual',
				description: 'Operation and Maintenance Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/VMAX_Hole_Punch/manuals/Vmax-Hole-Punch-Manual-01-30-20.pdf',
			},

			{
				type: 'SSD',
				description: 'Allen-Bradley SensaGuard Installation Instructions',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/VMAX_Hole_Punch/documentation/440n-in018-en.pdf',
			},

			{
				type: 'SSD',
				description: 'Banner TL70 Modular Tower Light Instructions',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/VMAX_Hole_Punch/documentation/banner-tl70.pdf',
			},

			{
				type: 'SSD',
				description: 'Moeller True-Set Retainers',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/VMAX_Hole_Punch/documentation/MPT-True-Set-Retainer-rev-2017.pdf',
			},

			{
				type: 'SSD',
				description: 'Moeller True-Set Punch Strippers',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/VMAX_Hole_Punch/documentation/MPT-True-Strip-rev-3-2014.pdf',
			},

			{
				type: 'SSD',
				description: 'TOX-Powerpackage Operating Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/VMAX_Hole_Punch/documentation/TOX_Manual_Powerpackage_201305_en.pdf',
			},
		],
	},

	{
		name: 'VMAX Rear Bulkhead Press',
		documents: [
			{
				type: 'Ledger',
				description: 'VMAX Rear Bulkhead Press Ledger',
				link: 'https://docs.google.com/spreadsheets/d/13nfYqZUa5rJWGQneYrlaMvUbZqdCy03UE_r4tSyzEls/edit#gid=1670184335',
			},

			{
				type: 'Manual',
				description: 'Operation and Maintenance Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Rear_Bulkhead_Press/manuals/Whirlpool%20Rear%20Bulkhead%20Press%20Operation%20and%20Maintenance%20Manual.pdf',
			},

			{
				type: 'SMP',
				description: 'SMP-1 - Mechanical Maintenance - TOX Powerpackage Replacement',
				link: 'https://docs.google.com/document/d/1fUAOtHZbr-FVVwzVaerDOzRLCzOgyvhD2w4Q4RmqMi0/edit',
				//All Rear Bulkhead Press SMPs locked
			},

			{
				type: 'SMP',
				description: 'SMP-2 - Mechanical Maintenance - Rod Lock Replacement',
				link: 'https://docs.google.com/document/d/1W9gVWwLUxQK7lP_MvHDzxd6q8kfocVpqZXkHQzflgao/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-3 - Mechanical Maintenance - SMC Compact Guide Replacement',
				link: 'https://docs.google.com/document/d/1CcCN7VpLYJdrjDZJOQfkkU4HBs8AnPiVbpAm97FHua0/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-4 - Mechanical Maintenance - Clamp Jaw Replacement',
				link: 'https://docs.google.com/document/d/16VKSHIEQ_vaMmRNURc0clYiXm_phiVT-ddhwiUqoBtU/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-5 - Mechanical Maintenance - Clamp Cylinder Replacement',
				link: 'https://docs.google.com/document/d/1qOjk9oNYFW9W_2Fc3AhhSGRv6M7yOInu5_TV7r3SXaI/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-6 - Mechanical Maintenance - Die Assembly Track Roller Replacement',
				link: 'https://docs.google.com/document/d/1QZyNKY2lZJcZxXyNGu1BzOPRdrf_-kx46THm1atjkL0/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-7 - Mechanical Maintenance - Date Stamp Cylinder Replacement',
				link: 'https://docs.google.com/document/d/1Njaa6yhA_naKuJ-O-cHSXQ_JZGOjkH5-TyZCwRaMfWM/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-8 - Mechanical Maintenance - Punch and Button Replacement',
				link: 'https://docs.google.com/document/d/1mJz5muoUBFUXqLQt_vZrWWbwVKamVILW6Iuyw3Kgmro/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-9 - Electrical Maintenance - Allen Bradley Panelview Plus Replacement',
				link: 'https://docs.google.com/document/d/1af2g-rpAilgRbVFir8Et5oDDuIP41gvr_369V6q4KHs/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-10 - Electrical Maintenance - SOLA Power Supply Replacement',
				link: 'https://docs.google.com/document/d/1CtkilbCXv6rOo9ZIOYiVyJgHS9rKNZvzShrD-udxDm0/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-11 - Pneumatic Maintenance - Coalescing Filter Replacement',
				link: 'https://docs.google.com/document/d/1psB2ibQbuub2NChAFhlRJalRH7r6BsJAH9Dq2hA1Tz4/edit',
			},

			{
				type: 'SMP',
				description: 'SMP-12 - Pneumatic Maintenance - Solenoid Valve Replacement',
				link: 'https://docs.google.com/document/d/1mINtgItlOWKrZy8AkjOQUBI01VfS-c1gxZ22c80ETFU/edit',
			},

			{
				type: 'SSD',
				description: 'Allen-Bradley SensaGuard Installation Instructions',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Rear_Bulkhead_Press/documentation/440n-in018-en.pdf',
			},

			{
				type: 'SSD',
				description: 'ASB-OC Spring Balancer Exploded View',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Rear_Bulkhead_Press/documentation/ASB-OC-spring-balancer.pdf',
			},

			{
				type: 'SSD',
				description: 'Banner TL70 Modular Tower Light Instructions',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Rear_Bulkhead_Press/documentation/banner-tl70.pdf',
			},

			{
				type: 'SSD',
				description: 'Moeller True-Set Retainers',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Rear_Bulkhead_Press/documentation/MPT-True-Set-Retainer-rev-2017.pdf',
			},

			{
				type: 'SSD',
				description: 'Moeller True-Set Punch Strippers',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Rear_Bulkhead_Press/documentation/MPT-True-Strip-rev-3-2014.pdf',
			},

			{
				type: 'SSD',
				description: 'Multi-Power Air Presses Catalog',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Rear_Bulkhead_Press/documentation/MP_press.pdf',
			},

			{
				type: 'SSD',
				description: 'TOX-Powerpackage Operating Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/marion/Rear_Bulkhead_Press/documentation/TOX_Manual_Powerpackage_201305_en.pdf',
			},
		],
	},
];

export default MarionData;